const indexOfNode = (arr = [], matchAttr) => {
  return arr.findIndex(x => x.node.id === matchAttr);
};

const nextNode = (arr = [], index, total) => {
  return index >= 0 && index !== total - 1
    ? arr[index + 1]
    : index === total - 1
    ? arr[0]
    : null;
};

const prevNode = (arr = [], index, total) => {
  return index <= total - 1 && index !== 0
    ? arr[index - 1]
    : index === 0
    ? arr[total - 1]
    : null;
};

export { indexOfNode, nextNode, prevNode };
