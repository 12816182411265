import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import cx from "classnames";
import * as pageUtils from "../utils/pageUtils";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import { Wrapper, BlockContent, ProjectThumbnailCard } from "../components";

export default function projectPageTemplate({ data }) {
  const {
    id,
    slug,
    title,
    category,
    skills,
    client,
    coverImage,
    _rawContent,
  } = data.sanityProject;

  const _nextNode = pageUtils.nextNode(
    data.allSanityProject.edges,
    pageUtils.indexOfNode(data.allSanityProject.edges, id),
    data.allSanityProject.totalCount
  );

  const _prevNode = pageUtils.prevNode(
    data.allSanityProject.edges,
    pageUtils.indexOfNode(data.allSanityProject.edges, id),
    data.allSanityProject.totalCount
  );

  const _mapSkills = arr => {
    if (arr.length === 0) return "No skills";
    return arr.map((item, index) => {
      const dot =
        index === arr.length - 1 ? null : (
          <div className="w-6 h-6 rounded-full bg-accent1 mr-6 mt-2"></div>
        );

      return (
        <div key={index} className="flex items-center">
          <p className="text-sm text-text3 mr-6">{item.title}</p>
          {dot}
        </div>
      );
    });
  };

  const _moreWorkThumbnail = node => {
    const to = `/work/${node.slug.current}`;
    const title = node.title ?? "No title";
    const skills = node.skills ?? [];
    const coverImageUrl =
      node.coverImage?.asset?.fluid?.src ??
      "https://benzai.github.io/shits-test-assets/avatars/puang.png";

    return (
      <ProjectThumbnailCard
        key={node.id}
        to={to}
        title={title}
        skills={skills}
        imageUrl={coverImageUrl}
        isShowCoverImageBorder={node.isShowCoverImageBorder}
      />
    );
  };

  const projectInfoBlock = () => {
    return (
      <div className="flex flex-col items-center w-10/12 lg:w-5/12">
        <h1 className="font-medium text-md2 md:text-md lg:text-lg4 text-text1 tracking-tight_md leading-none text-center">
          {title}
        </h1>
        <div className="bg-transparent h-10 lg:h-20" />
        <div className="flex">{_mapSkills(skills)}</div>
        <div className="bg-transparent h-20" />
        <p className="text-sm text-text1">
          <span className="text-sm3 text-text3 uppercase mr-6">Client</span>
          {client}
        </p>
      </div>
    );
  };

  return (
    <Layout>
      <SEO title={title} />
      <div className="bg-bg1">
        <section className="py-40 lg:py-60">
          <Wrapper>
            <div className="flex flex-col items-center">
              {projectInfoBlock()}
            </div>
          </Wrapper>
        </section>

        <section className="pb-40 md:pb-60">
          <Wrapper>
            <BlockContent blocks={_rawContent || []} />
          </Wrapper>
        </section>

        <section id="moreWork" className="px-20 lg:px-60 xl:px-80">
          <Wrapper>
            <h2 className="font-medium text-md2 lg:text-md text-accent1 tracking-tight_lg text-center">
              More work
            </h2>
            <div className="bg-transparent h-20 lg:h-40" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-15 md:gap-20 pb-60">
              {_moreWorkThumbnail(_prevNode.node)}
              {_moreWorkThumbnail(_nextNode.node)}
            </div>
          </Wrapper>
        </section>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    sanityProject(id: { eq: $id }) {
      id
      title
      category {
        title
      }
      skills {
        title
      }
      client
      coverImage {
        asset {
          fluid {
            src
          }
        }
      }
      _rawContent
    }

    allSanityProject(
      filter: { isActive: { eq: true } }
      sort: { fields: sortOrder, order: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          slug {
            current
          }
          title
          category {
            title
          }
          skills {
            title
          }
          client
          coverImage {
            asset {
              fluid {
                src
              }
            }
          }
          isShowCoverImageBorder
        }
      }
    }
  }
`;
